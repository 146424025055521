// AtomicNetDocument represents a document that is exchanged by AtomicNet between DemoActors.
//
// This file contains the AtomicNetDocument interface, as well as the Documents database of all
// documents shown in the demo.

import {ActorNameType, DemoScenario} from "./DemoActor";

export type DocumentType =
    "o1" | "o2" |
    "bar1" | "ba1" | "bar2" | "ba2" |
    "ecr1" | "ec1" | "ecr2" | "ec2" |
    "tbar1" | "tbar2" | "tba1" | "tba2" |
    "tecr1" | "tec1" | "tecr2" | "tec2" |
    "qr" |
    "ob1" | "ob2" | "oe_to_abc" | "oe_to_c1" | "oe_to_c2" | "oe_to_l1" | "oe_to_l2" | "oe_to_oms1" | "oe_to_oms2" |
    "olp1_to_abc" | "olp2_to_abc" | "tolp1_to_abc" | "tolp2_to_abc" | "olp1_to_oms1" | "olp2_to_oms1" | "tolp1_to_oms1" | "tolp2_to_oms1" |
    "olp1_to_oms2" | "olp2_to_oms2" | "tolp1_to_oms2" | "tolp2_to_oms2" |
    "tc1" | "tc2";

// Used to create the DocPath for each document
export type AtomicNetDoc =
    "order" |
    "custodian_beneficiary_authorization_request" | "custodian_beneficiary_authorization" |
    "custodian_escrow_confirmation" | "custodian_escrow_confirmation_request" |
    "transfer_beneficiary_authorization_request" | "transfer_beneficiary_authorization" |
    "transfer_escrow_confirmation" | "transfer_escrow_confirmation_request" |
    "transaction_completed" |
    "quote_request" |
    "order_bundle" |
    "order_execution_confirmation" | "order_leg_processed" | "transfer_order_leg_processed";

export interface AtomicNetDocument {
    name: string,
    longDocType: AtomicNetDoc, // Should describe the document, and if there are multiple in the flow, end with a number.
    placePercentage: number,
    srcActor: ActorNameType,
    dstActor: ActorNameType,
    subDocuments?: Set<string>,
}

// A long document name that uniquely describes each AtomicNet Document.
// For example: Custodian Beneficiary Authorization Request
//
// This is converted from the document.name, which is a specific document in the transaction flow of AtomicNet.
// This assumes that document.name ends with a number (generally 1 or 2) that when removed is the
// unique full document name.
export function fullDocumentName(document: AtomicNetDocument): string {
    return document.name.toString().replace(/\s?\d$/, '');
}

interface AtomicNetDocumentOptions {
    srcActor?: ActorNameType,
    dstActor?: ActorNameType,
    subDocuments?: Set<string>,
    placePercentage?: number,
}

const AllDocuments = new Map<DocumentType, AtomicNetDocument>([
    [ "o1", {
        name: "Order 1",
        longDocType: "order",
        placePercentage: 35,
        srcActor: "ia1",
        dstActor: "oms1",
    }], [ "o2", {
        name: "Order 2",
        longDocType: "order",
        placePercentage: 35,
        srcActor: "ia2",
        dstActor: "oms2",
    }], [ "bar1", {
        name: "Custodian Beneficiary Authorization Request 1",
        longDocType: "custodian_beneficiary_authorization_request",
        placePercentage: 40,
        srcActor: "oms1",
        dstActor: "custodian1",
    }], [ "ba1", {
        name: "Custodian Beneficiary Authorization 1",
        longDocType: "custodian_beneficiary_authorization",
        placePercentage: 25,
        srcActor: "custodian1",
        dstActor: "oms1",
        subDocuments: new Set(["tba1"])
    }], [ "bar2", {
        name: "Custodian Beneficiary Authorization Request 2",
        longDocType: "custodian_beneficiary_authorization_request",
        placePercentage: 40,
        srcActor: "oms2",
        dstActor: "custodian2",
    }], [ "ba2", {
        name: "Custodian Beneficiary Authorization 2",
        longDocType: "custodian_beneficiary_authorization",
        placePercentage: 25,
        srcActor: "custodian2",
        dstActor: "oms2",
        subDocuments: new Set(["tba1"])
    }], [ "ecr1", {
        name: "Custodian Escrow Confirmation Request 1",
        longDocType: "custodian_escrow_confirmation_request",
        placePercentage: 25,
        srcActor: "oms1",
        dstActor: "custodian1",
    }], [ "ec1", {
        name: "Custodian Escrow Confirmation 1",
        longDocType: "custodian_escrow_confirmation",
        placePercentage: 15,
        srcActor: "custodian1",
        dstActor: "oms1",
        subDocuments: new Set(["tec1"]),
    }], [ "ecr2", {
        name: "Custodian Escrow Confirmation Request 2",
        longDocType: "custodian_escrow_confirmation_request",
        placePercentage: 25,
        srcActor: "oms2",
        dstActor: "custodian2",
    }], [ "ec2", {
        name: "Custodian Escrow Confirmation 2",
        longDocType: "custodian_escrow_confirmation",
        placePercentage: 15,
        srcActor: "custodian2",
        dstActor: "oms2",
        subDocuments: new Set(["tec2"])
    }], [ "tbar1", {
        name: "Transfer Beneficiary Authorization Request 1",
        longDocType: "transfer_beneficiary_authorization_request",
        placePercentage: 40,
        srcActor: "custodian1",
        dstActor: "common_ledger1",
    }], [ "tba1", {
        name: "Transfer Beneficiary Authorization 1",
        longDocType: "transfer_beneficiary_authorization",
        placePercentage: 25,
        srcActor: "common_ledger1",
        dstActor: "custodian1",
    }], [ "tbar2", {
        name: "Transfer Beneficiary Authorization Request 2",
        longDocType: "transfer_beneficiary_authorization_request",
        placePercentage: 40,
        srcActor: "custodian2",
        dstActor: "common_ledger2",
    }], [ "tba2", {
        name: "Transfer Beneficiary Authorization 2",
        longDocType: "transfer_beneficiary_authorization",
        placePercentage: 25,
        srcActor: "common_ledger2",
        dstActor: "custodian2",
    }], [ "tecr1", {
        name: "Transfer Escrow Confirmation Request 1",
        longDocType: "transfer_escrow_confirmation_request",
        placePercentage: 15,
        srcActor: "custodian1",
        dstActor: "common_ledger2",
    }], [ "tec1", {
        name: "Transfer Escrow Confirmation 1",
        longDocType: "transfer_escrow_confirmation",
        placePercentage: 25,
        srcActor: "common_ledger2",
        dstActor: "custodian1",
    }], [ "tecr2", {
        name: "Transfer Escrow Confirmation Request 2",
        longDocType: "transfer_escrow_confirmation_request",
        placePercentage: 15,
        srcActor: "custodian2",
        dstActor: "common_ledger1",
    }], [ "tec2", {
        name: "Transfer Escrow Confirmation 2",
        longDocType: "transfer_escrow_confirmation",
        placePercentage: 25,
        srcActor: "common_ledger1",
        dstActor: "custodian2",
    }], [ "qr", {
        name: "Quote Request",
        longDocType: "quote_request",
        placePercentage: 10,
        srcActor: "oms1",
        dstActor: "oms2",
        subDocuments: new Set([])
    }], [ "ob1", {
        name: "Order Bundle 1",
        longDocType: "order_bundle",
        placePercentage: 28,
        srcActor: "oms1",
        dstActor: "exchange",
        subDocuments: new Set(["ba1", "ec1"])
    }], [ "ob2", {
        name: "Order Bundle 2",
        longDocType: "order_bundle",
        placePercentage: 28,
        srcActor: "oms2",
        dstActor: "exchange",
        subDocuments: new Set(["ba2", "ec2"])
    }], [ "oe_to_abc", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 30,
        srcActor: "exchange",
        dstActor: "abc",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "oe_to_c1", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 25,
        srcActor: "abc",
        dstActor: "custodian1",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "oe_to_c2", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 25,
        srcActor: "abc",
        dstActor: "custodian2",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "oe_to_l1", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 35,
        srcActor: "abc",
        dstActor: "common_ledger1",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "oe_to_l2", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 35,
        srcActor: "abc",
        dstActor: "common_ledger2",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "oe_to_oms1", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 40,
        srcActor: "abc",
        dstActor: "oms1",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "oe_to_oms2", {
        name: "Order Execution Confirmation",
        longDocType: "order_execution_confirmation",
        placePercentage: 40,
        srcActor: "abc",
        dstActor: "oms2",
        subDocuments: new Set(["ob1", "ob2"])
    }], [ "olp1_to_abc", {
        name: "Custodian Order Leg Processed 1",
        longDocType: "order_leg_processed",
        placePercentage: 15,
        srcActor: "custodian1",
        dstActor: "abc",
    }], [ "olp2_to_abc", {
        name: "Custodian Order Leg Processed 2",
        longDocType: "order_leg_processed",
        placePercentage: 15,
        srcActor: "custodian2",
        dstActor: "abc",
    }], [ "tolp1_to_abc", {
        name: "Transfer Order Leg Processed 1",
        longDocType: "transfer_order_leg_processed",
        placePercentage: 15,
        srcActor: "common_ledger1",
        dstActor: "abc",
    }], [ "tolp2_to_abc", {
        name: "Transfer Order Leg Processed 2",
        longDocType: "transfer_order_leg_processed",
        placePercentage: 15,
        srcActor: "common_ledger2",
        dstActor: "abc",
    }], [ "tolp1_to_oms1", {
        name: "Transfer Order Leg Processed 1",
        longDocType: "transfer_order_leg_processed",
        placePercentage: 35,
        srcActor: "abc",
        dstActor: "oms1",
    }], [ "tolp2_to_oms1", {
        name: "Transfer Order Leg Processed 2",
        longDocType: "transfer_order_leg_processed",
        placePercentage: 30,
        srcActor: "abc",
        dstActor: "oms1",
    }], [ "olp1_to_oms1", {
        name: "Custodian Order Leg Processed 1",
        longDocType: "order_leg_processed",
        placePercentage: 25,
        srcActor: "abc",
        dstActor: "oms1",
    }], [ "olp2_to_oms1", {
        name: "Custodian Order Leg Processed 2",
        longDocType: "order_leg_processed",
        placePercentage: 20,
        srcActor: "abc",
        dstActor: "oms1",
    }], [ "tolp1_to_oms2", {
        name: "Transfer Order Leg Processed 2",
        longDocType: "transfer_order_leg_processed",
        placePercentage: 35,
        srcActor: "abc",
        dstActor: "oms2",
    }], [ "tolp2_to_oms2", {
        name: "Transfer Order Leg Processed 1",
        longDocType: "transfer_order_leg_processed",
        placePercentage: 30,
        srcActor: "abc",
        dstActor: "oms2",
    }], [ "olp1_to_oms2", {
        name: "Custodian Order Leg Processed 1",
        longDocType: "order_leg_processed",
        placePercentage: 25,
        srcActor: "abc",
        dstActor: "oms2",
    }], [ "olp2_to_oms2", {
        name: "Custodian Order Leg Processed 2",
        longDocType: "order_leg_processed",
        placePercentage: 20,
        srcActor: "abc",
        dstActor: "oms2",
    }], [ "tc1", {
        name: "Transaction Completed Confirmation 1",
        longDocType: "transaction_completed",
        placePercentage: 40,
        srcActor: "oms1",
        dstActor: "ia1",
        subDocuments: new Set(["oe_to_abc", "olp1_to_oms1", "olp2_to_oms1", "tolp1_to_oms1", "tolp2_to_oms1"]),
    }], [ "tc2", {
        name: "Transaction Completed Confirmation 2",
        longDocType: "transaction_completed",
        placePercentage: 40,
        srcActor: "oms2",
        dstActor: "ia2",
        subDocuments: new Set(["oe_to_abc", "olp1_to_oms2", "olp2_to_oms2", "tolp1_to_oms2", "tolp2_to_oms2"]),
    }],
]);

const CustodianRemoveDocs = new Set<DocumentType>([
    "qr",
]);

const ImpliedDeliveryRemoveDocs= new Set<DocumentType>([
    "tbar1", "tba1", "tecr2", "tec2",
    "qr",
    "oe_to_l1",
    "tolp1_to_abc", "tolp1_to_oms1", "tolp1_to_oms2"
]);

const ImpliedDeliveryModifyDocs = new Map<DocumentType, AtomicNetDocumentOptions>([
    [ "ba1", {
        subDocuments: new Set([]),
    }], [ "tecr2", {
        srcActor: "custodian2",
        dstActor: "custodian1",
        subDocuments: new Set([]),
    }],
]);

const NoCustodianRemoveDocs = new Set<DocumentType>([
    "bar1", "bar2", "ba1", "ba2", "ecr1", "ecr2", "ec1", "ec2",
    "oe_to_c1", "oe_to_c2",
    "olp1_to_abc", "olp2_to_abc", "olp1_to_oms1", "olp1_to_oms2", "olp2_to_oms2", "olp2_to_oms1"
]);

const NoCustodianModifyDocs = new Map<DocumentType, AtomicNetDocumentOptions>([
    [ "tbar1", {
        srcActor: "oms1",
        dstActor: "common_ledger1",
    }], [ "tba1", {
        srcActor: "common_ledger1",
        dstActor: "oms1",
    }], [ "tbar2", {
        srcActor: "oms2",
        dstActor: "common_ledger2",
    }], [ "tba2", {
        srcActor: "common_ledger2",
        dstActor: "oms2",
    }], [ "tecr1", {
        srcActor: "oms1",
        dstActor: "common_ledger2",
        placePercentage: 25,
    }], [ "tec1", {
        srcActor: "common_ledger2",
        dstActor: "oms1",
    }], [ "tecr2", {
        srcActor: "oms2",
        dstActor: "common_ledger1",
        placePercentage: 25,
    }], [ "tec2", {
        srcActor: "common_ledger1",
        dstActor: "oms2",
    }], [ "ob1", {
        subDocuments: new Set(["tba1", "tec1"]),
    }], [ "ob2", {
        subDocuments: new Set(["tba2", "tec2"]),
    }], [ "oe_to_oms1", {
        placePercentage: 30,
    }], [ "tolp1_to_oms1", {
        placePercentage: 25,
    }], [ "tolp2_to_oms1", {
        placePercentage: 20,
    }], [ "oe_to_oms2", {
        placePercentage: 30,
    }], [ "tolp1_to_oms2", {
        placePercentage: 25,
    }], [ "tolp2_to_oms2", {
        placePercentage: 20,
    }], [ "tc1", {
        subDocuments: new Set(["oe_to_abc", "tolp1_to_oms1", "tolp2_to_oms1"]),
    }], [ "tc2", {
        subDocuments: new Set(["oe_to_abc", "tolp1_to_oms2", "tolp2_to_oms2"]),
    }],
]);

const NoExchangeRemoveDocs = new Set<DocumentType>([
    "oe_to_oms1",
    "tbar2", "tba2", // "tecr1", "tec1",
    "qr",
    "oe_to_l2",
    "tolp2_to_abc", "tolp2_to_oms1", "tolp2_to_oms2"
]);

const NoExchangeModifyDocs = new Map<DocumentType, AtomicNetDocumentOptions>([
    [ "tecr1", {
        srcActor: "custodian1",
        dstActor: "custodian2",
    }], [ "tec1", {
        srcActor: "custodian2",
        dstActor: "custodian1",
        placePercentage: 50,
    }], [ "tecr2", {
        placePercentage: 50,
    }], [ "ob1", {
        srcActor: "oms1",
        dstActor: "oms1",
    }], [ "ob2", {
        srcActor: "oms2",
        dstActor: "oms1",
        placePercentage: 10,
    }], [ "oe_to_abc", {
        srcActor: "oms1",
        dstActor: "abc",
        placePercentage: 10,
    }],
]);

interface DocChanges {
    remove_docs: Set<DocumentType>|undefined,
    modify_docs: Map<DocumentType, AtomicNetDocumentOptions>|undefined,
};

const ScenarioDocMap = new Map<DemoScenario, DocChanges>([
    [ "custodian", {
        remove_docs: CustodianRemoveDocs,
        modify_docs: undefined,
    }], [ "implied_delivery", {
        remove_docs: ImpliedDeliveryRemoveDocs,
        modify_docs: ImpliedDeliveryModifyDocs,
    }], [ "no_custodian", {
        remove_docs: NoCustodianRemoveDocs,
        modify_docs: NoCustodianModifyDocs,
    }], [ "no_exchange", {
        remove_docs: NoExchangeRemoveDocs,
        modify_docs: NoExchangeModifyDocs,
    }]]
);

export function docTypeInCurScenario(doc: DocumentType, scenario: DemoScenario): boolean {
    let docMap = ScenarioDocMap.get(scenario);
    let doc_excluded = docMap !== undefined && docMap.remove_docs !== undefined && docMap.remove_docs.has(doc);

    return !doc_excluded;
}

// Return an array of the documents used in the current scenario
export function generateScenarioDocuments(scenario: DemoScenario): {[document: string]: AtomicNetDocument} {
    let docMap = ScenarioDocMap.get(scenario);
    let documents: {[document: string]: AtomicNetDocument} = {};

    AllDocuments.forEach((document, docType) => {
        let skip = false;
        let new_document: AtomicNetDocument = {
            name: document.name,
            longDocType: document.longDocType,
            placePercentage: document.placePercentage,
            srcActor: document.srcActor,
            dstActor: document.dstActor,
            subDocuments: document.subDocuments,
        }

        if (docMap !== undefined) {
            if (docMap.remove_docs !== undefined) {
                if (docMap.remove_docs.has(docType)) {
                    skip = true;
                }
            }
            if (docMap.modify_docs !== undefined) {
                let modify = docMap.modify_docs.get(docType);

                if (modify !== undefined) {
                    if (modify.srcActor !== undefined) {
                        new_document.srcActor = modify.srcActor;
                    }
                    if (modify.dstActor !== undefined) {
                        new_document.dstActor = modify.dstActor;
                    }
                    if (modify.subDocuments !== undefined) {
                        new_document.subDocuments = modify.subDocuments;
                    }
                    if (modify.placePercentage !== undefined) {
                        new_document.placePercentage = modify.placePercentage;
                    }
                }
            }
        }
        if (!skip) {
            documents[docType] = new_document;
        }
    })

    return documents;
}

const ScenarioDocuments: { [scenario: string]: {[document: string]: AtomicNetDocument}} = {
    no_custodian: generateScenarioDocuments("no_custodian"),
    implied_delivery: generateScenarioDocuments("implied_delivery"),
    custodian: generateScenarioDocuments("custodian"),
    no_exchange: generateScenarioDocuments("no_exchange"),
}

export function getScenarioDocuments(scenario: DemoScenario): {[document: string]: AtomicNetDocument} {
    return ScenarioDocuments[scenario];
}