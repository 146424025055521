import Button from '@mui/material/Button';
import { Form, Field } from "react-final-form";
import TextInput from "../form_components/TextInput"
import MultiSelectInput from "../form_components/MultiSelectInput";
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {assetToCommonLedgerMap, onOrderSubmission} from "../audit_log/auditSlice";
import { Tooltip } from 'react-tooltip'
// TODO this is coming right back import NetworkDropdown from '../form_components/NetworkDropdown';
import { NavigationArrow } from '../SvgGraph/NavigationArrows';
import NetworkDropdown from '../form_components/NetworkDropdown';
import { useSearchParams } from 'react-router-dom';

// import ForceGraph from '../forceGraph';
import {SvgGraph} from '../SvgGraph/SvgGraph';

import logo from '../../assets/images/AtomicNet_Logo.png'
import ScenarioDropdown from "../form_components/ScenarioDropdown";

export type OrderSide = "buy" | "sell";

export interface OrderInput {
    clientOne: string;
    purchasingEntity: string;
    custodian: string;
    primaryAssetLedger: string,
    commonBank: string;
    fiatCurrency: string;
    asset: string;
    exchange: string;
    quantity: number;
    side: OrderSide,
}
  
function SvgOrLogo(auditLogLength : string) {
    if (auditLogLength !== "") {
        return <SvgGraph/>;
    } else {
        return <div id="beforeTicketLogo">
            <img src={logo} width="400px" alt="The logo of AtomicNet"></img>
        </div>;
    }
}

export function OmsInput() {
    const [searchParams] = useSearchParams();
    let shouldPrefill = searchParams.get('prefill') === "true";

    let initialValuesForForm = {};
    let scenario = useAppSelector(state => state.audit.scenario);
    if (shouldPrefill) {
        initialValuesForForm = {
            purchasingEntity: ["REIT"],
            orderSide: "buy",
            asset: [scenario === "no_exchange" ? "SPV1" : "POLY"],
            limitPrice: "",
            orderType: "market",
            quantity: 1000,
            fiatCurrency: "USD",
            custodian: ["SCTC"],
            primaryAssetLedger: ["PAL"],
            commonBank: ["BKKLY"],
            exchange: ["XCHG"],
        }
    }

    const dispatch = useAppDispatch()
    let auditLogLength = useAppSelector(state => state.audit.sender);
    const handleSubmit = async (values: OrderInput) => {
        dispatch(onOrderSubmission(values));
    };

    const inputCustodian = scenario === 'no_custodian' ? ''
        : (<div>
            <label>Custodian</label>
            <Field<string[]> id="ListBoxSelect" name="custodian" component={MultiSelectInput}>
                <option value="SCTC">SCTC</option>
                <option value="Pacific Blue">Pacific Blue</option>
            </Field>
        </div>);

    const inputBank = scenario === 'implied_delivery' ? ''
        : (<div>
            <label>Bank</label>
            <Field<string[]> id="ListBoxSelect" name="commonBank" component={MultiSelectInput}>
                <option id="ListBoxValue" value="BKKLY">BKKLY</option>
                <option id="ListBoxValue" value="SCBFY">SCBFY</option>
                <option id="ListBoxValue" value="SINGMAS">SINGMAS</option>
                <option id="ListBoxValue" value="BOKKR">BOKKR</option>
                <option id="ListBoxValue" value="BBCA">BBCA</option>
            </Field>
        </div>);

    const inputExchange = scenario === 'no_exchange' ? ''
        : (<div>
            <label>Exchange</label>
            <Field<string[]> id="ListBoxSelect" name="exchange" component={MultiSelectInput}>
                <option id="ListBoxValue" value="XCHG">XCHG</option>
                <option id="ListBoxValue" value="FXCHG">FXCHG</option>
                <option id="ListBoxValue" value="FICHG">FICHG</option>
                <option id="ListBoxValue" value="DIATS">DIATS</option>
            </Field>
        </div>);
    const inputAsset = scenario === 'no_exchange'
        ? (<div>
            <label>Asset</label>
            <Field<string[]> id="ListBoxSelect" name="asset" component={MultiSelectInput}>
                <option id="ListBoxValue" value="QQDa">QQda - {assetToCommonLedgerMap.get("QQda")}</option>
                <option id="ListBoxValue" value="SPV1">SPV1 - {assetToCommonLedgerMap.get("SPV1")}</option>
                <option id="ListBoxValue" value="TYAda">TYAda - {assetToCommonLedgerMap.get("TYAda")}</option>
            </Field>
        </div>)
        : (<div>
            <label>Asset</label>
            <Field<string[]> id="ListBoxSelect" name="asset" component={MultiSelectInput}>
                <option id="ListBoxValue" value="POLY">POLY - {assetToCommonLedgerMap.get("POLY")}</option>
                <option id="ListBoxValue" value="QQQ">QQQ - {assetToCommonLedgerMap.get("QQQ")}</option>
                <option id="ListBoxValue" value="PLTR">PLTR - {assetToCommonLedgerMap.get("PLTR")}</option>
                <option id="ListBoxValue" value="ETF">ETF - {assetToCommonLedgerMap.get("ETF")}</option>
            </Field>
        </div>);

    return (
        <div className="inputAndDiagram">
          <div className="contentCard">
          <div className="tooltip-container">
            <Tooltip id="disclaimer-tooltip"/>
          </div>
          <div className="container-fluid topStrip">
            <div className="row">
            <div className="col" id="logo">
              <img src={logo} width="200" style={{borderWidth: 0,}} />
            </div>
            <div className="col" id="disclaimer">
              <a href="#" id="link" data-tooltip-id="disclaimer-tooltip"
              data-tooltip-html="<p>The content contained herein, including the order ticket, communication flow and audit trail are created in a test environment and are fictitious.<br/> Any information provided on this website is displayed for informational and demo purposes only,<br/> and is not intended to constitute trading or other advice.</p>"data-tooltip-content="YO!">DISCLAIMER</a>
            </div>
            <div className="col-4" id="page_title">
              <h1 id="title">AtomicNet Testing Sandbox</h1>
              <span id="subtitle">Minimal Trade Friction: Instant Participant Settlement</span>
            </div>
            <div className="col" id="timeArea">
            <NavigationArrow></NavigationArrow>
            </div>
                <div className="col" id="dropdown">
                    <ScenarioDropdown/>
                </div>
            <div className="col" id="dropdown">
              <NetworkDropdown/>
            </div>
          </div>
          </div>

            {/* Begin main content */}
            <div className="inlineContent anoms">
              <Form
              onSubmit={handleSubmit}
              initialValues={initialValuesForForm}
              render={({ handleSubmit, form, submitting, pristine, values }) => (

                <form onSubmit={handleSubmit}>
                  <h3 id="orderTicketTitle">ORDER TICKET</h3> {/* TODO same placement as Communication Flow */}
                    <div>
                        <label>Fund Complex</label>
                        { /* , , REIT, Tech Infrastructure */}
                        <div id="SelectDiv">
                            <Field<string[]> id="ListBoxSelect" name="purchasingEntity" component={MultiSelectInput}>
                                <option id="ListBoxValue" value="Digital Tactical" >Digital Tactical</option>
                                <option id="ListBoxValue" value="Small Cap Growth">Small Cap Growth</option>
                                <option id="ListBoxValue" value="REIT">REIT</option>
                                <option id="ListBoxValue" value="Tech Infrastructure">Tech Infrastructure</option>
                            </Field>
                        </div>
                    </div>
                    <div className="radioField">
                        <label>Side</label>
                        <label className="interiorRadioLabel">
                            <Field
                                name="orderSide"
                                component="input"
                                type="radio"
                                value="buy"
                            />{' '}
                            Buy
                            <Field
                                name="orderSide"
                                component="input"
                                type="radio"
                                value="sell"
                                className="secondChoiceMargin"
                            />{' '}
                            Sell
                        </label>
                    </div>
                    {inputAsset}
                    <div>
                        <label>Size</label>
                        <Field<string>
                            name="quantity"
                            component={TextInput}
                            placeholder="Size"
                        />
                    </div>
                    <div className="radioField">
                        <label>Order Type</label>
                        <label className="interiorRadioLabel">
                            <Field
                                name="orderType"
                                component="input"
                                type="radio"
                                value="market"
                            />{' '}
                            Market
                            <Field
                                name="orderType"
                                component="input"
                                type="radio"
                                value="limit"
                                className="secondChoiceMargin"
                            />{' '}
                            Limit
                        </label>
                    </div>
                    <div>
                        <label>Limit Price</label>
                        <Field<string>
                            name="limitPrice"
                            component={TextInput}
                            placeholder="Limit Price"
                            value="limitPrice"
                        />
                    </div>
                    <div className="radioField">
                        <label>Currency</label>
                        <label className="interiorRadioLabel">
                            <Field
                                name="fiatCurrency"
                                component="input"
                                type="radio"
                                value="USD"
                            />{' '}
                            <span>USD</span>
                            <Field
                                name="fiatCurrency"
                                component="input"
                                type="radio"
                                value="EUR"
                                className="secondChoiceMargin"
                            />{' '}
                            <span>EUR</span>
                        </label>
                    </div>
                    {inputExchange}
                    {inputBank}
                    {inputCustodian}
                    <div className="buttons" id="submitButton">
                        <Button className="button" variant="text" type="submit" disabled={submitting}>Submit</Button>
                    </div>
                </form>
              )}
            />
          <div className="diagram-component">
            <div>
            {SvgOrLogo(auditLogLength)}
            </div>

          </div>
            </div>
            <div>

            </div>
          </div>
        </div>
    )
}