///
/// Generate the ledger events for actors that have them based on the log entries.
///

import moment from "moment";
import {AuditEntry, EventType} from "./auditSlice";
import {LedgerEvent} from "../SvgGraph/ActorLedger";
import {DemoScenario} from "../SvgGraph/DemoActor";
import {OrderSide} from "../order_input/orderInput";

export const FiatPerAsset = 5;

// Return the timestamp for the given event or return zero
//
// This timestamp will be used in actor ledger entries.
const eventTimestampOrZero = (eventName: EventType, auditEntries: Array<AuditEntry>) => {
    let maybeEvent = auditEntries.find(x => x.event === eventName);

    if (maybeEvent) {
        return moment(maybeEvent.timestamp).format("hh:mm:ss.SSS") + "Z";
    } else {
        return "";
    }
}

// Generate events that will appear in the ledger boxes of the custodian and common ledger levels.
//
// These event will be highlighted depending on if the event occurs before or after the current log selection.
export const generateLedgerEvents = (auditStep: string, orderSide: OrderSide, quantity: number, auditEntries: Array<AuditEntry>, scenario: DemoScenario): LedgerEvent[] => {
    let fiatPerAsset = FiatPerAsset; // Eventually, this will be determined by the match

    // let buy = orderSide === "buy"; // TODO: a sell order should switch who is buying and who is selling in all scenarios
    let assetAmount = quantity;
    let fiatAmount = quantity * fiatPerAsset;

    let ia1StartingFiatBalance = fiatAmount;
    let ia2StartingFiatBalance = 0;
    let ia1StartingAssetBalance = 0;
    let ia2StartingAssetBalance = assetAmount;

    let custodian1StartingFiatBalance = 50_000 + ia1StartingFiatBalance;
    let custodian2StartingFiatBalance = 10_000 + ia2StartingFiatBalance;
    let custodian1StartingAssetBalance = 0 + ia1StartingAssetBalance;
    let custodian2StartingAssetBalance = 3_000 + ia2StartingAssetBalance;

    let timestampStartingBalance = eventTimestampOrZero("order sent", auditEntries);

    // Must choose an event that exists in the given scenario:
    // - "CEC received" (or any custodian originating event) does not exist in "no_custodian".
    // - "TOLP1 sent" (or any transfer from common_ledger 1 event)  does not exist in "implied_delivery".
    let escrowOneEvent: EventType = scenario === "no_custodian" ? "TEC received" : "CEC received";
    let escrowOneStamp = eventTimestampOrZero(escrowOneEvent, auditEntries);

    let swapOneEvent: EventType = scenario === "implied_delivery" ? "COLP1 sent" : "TOLP1 sent";
    let swapOneStamp = eventTimestampOrZero(swapOneEvent, auditEntries);

    let swapTwoStamp = eventTimestampOrZero("TOLP2 sent", auditEntries);

    let logToIndex: { [actor: string]: number } = {};
    auditEntries.forEach((value: AuditEntry, index: number) => {
        logToIndex[value.event] = value.index
    })
    let auditIndex = logToIndex[auditStep];
    let orderNotSubmitted = auditIndex < logToIndex["order sent"];
    let tecNotSent = auditIndex < logToIndex["TEC sent"];
    let cecNotSent = auditIndex < logToIndex["CEC sent"];

    let events: LedgerEvent[] = [{
        ledger_holder: "common_ledger1",
        account: "fiat",
        event: "starting_balance",
        time: timestampStartingBalance,
        amount1: scenario === "no_custodian" ? ia1StartingFiatBalance : custodian1StartingFiatBalance,
        amount2: scenario === "no_custodian" ? ia2StartingFiatBalance : custodian2StartingFiatBalance,
        in_future: orderNotSubmitted,
    }, {
        ledger_holder: "common_ledger2",
        account: "asset",
        event: "starting_balance",
        time: timestampStartingBalance,
        amount1: "no_custodian" ? ia1StartingAssetBalance : custodian1StartingAssetBalance,
        amount2: "no_custodian" ? ia2StartingAssetBalance : custodian2StartingAssetBalance,
        in_future: orderNotSubmitted,
    }, {
        ledger_holder: "custodian1",
        account: "ia1",
        event: "starting_balance",
        time: timestampStartingBalance,
        amount1: ia1StartingFiatBalance,
        amount2: ia1StartingAssetBalance,
        in_future: orderNotSubmitted,
    }, {
        ledger_holder: "custodian2",
        account: "ia2",
        event: "starting_balance",
        time: timestampStartingBalance,
        amount1: ia2StartingFiatBalance,
        amount2: ia2StartingAssetBalance,
        in_future: orderNotSubmitted,
    }, {
        ledger_holder: "common_ledger1",
        account: "fiat",
        event: "escrow",
        time: escrowOneStamp,
        amount1: fiatAmount,
        in_future: tecNotSent,
    }, {
        ledger_holder: "common_ledger2",
        account: "asset",
        event: "escrow",
        time: escrowOneStamp,
        amount1: assetAmount,
        in_future: tecNotSent,
    }, {
        ledger_holder: "custodian1",
        account: "ia1",
        event: "escrow",
        time: escrowOneStamp,
        amount1: fiatAmount,
        in_future: cecNotSent,
    }, {
        ledger_holder: "custodian2",
        account: "ia2",
        event: "escrow",
        time: escrowOneStamp,
        amount1: assetAmount,
        in_future: cecNotSent,
    }, {
        ledger_holder: "common_ledger1",
        account: "fiat",
        event: "transfer",
        time: swapTwoStamp,
        amount1: fiatAmount,
        in_future: auditIndex < logToIndex["TOLP2 sent"],
    }, {
        ledger_holder: "common_ledger2",
        account: "asset",
        event: "transfer",
        time: swapOneStamp,
        amount1: assetAmount,
        in_future: auditIndex < logToIndex["TOLP1 sent"],
    }, {
        ledger_holder: "custodian1",
        account: "asset",
        event: scenario === "implied_delivery" ? "fedwire_debit" : "debit",
        time: swapOneStamp,
        amount1: fiatAmount,
        in_future: false
    }, {
        ledger_holder: "custodian1",
        account: "fiat",
        event: "credit",
        time: swapOneStamp,
        amount1: 0,
        amount2: assetAmount,
        in_future: false
    }, {
        ledger_holder: "custodian2",
        account: "ia2",
        event: "debit",
        time: swapTwoStamp,
        amount1: 0,
        amount2: assetAmount,
        in_future: auditIndex < logToIndex["COLP2 sent"],
    }, {
        ledger_holder: "custodian2",
        account: "ia2",
        event: scenario === "implied_delivery" ? "fedwire_credit" : "credit",
        time: swapTwoStamp,
        amount1: fiatAmount,
        in_future: auditIndex < logToIndex["COLP2 sent"],
    }];

    return events;
}