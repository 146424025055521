//
// Given a document, return details so that they can be shown to users.
//
import {formatAssetAmount} from "./ActorLedger";
import {DemoGraphState} from "./DemoGraphState";
import {
    AtomicNetDoc,
    AtomicNetDocument,
    DocumentType,
    fullDocumentName,
    getScenarioDocuments
} from "./AtomicNetDocument";
import {DemoScenario} from "./DemoActor";

type FormData = {
    investmentAdvisor: string,
    omsId: string,
    custodianName: string,
    custodianId: string,
    commonLedgerId: string,
    oppositeCommonLedgerId: string,
    exchangeId: string,
    beneficiaryId: string,
    beneficiaryName: string,
    escrowAccountName: string,
    expires: string,
    giveAsset: string,
    getAsset: string,
    giveAmount: string|number,
    getAmount: string|number,
    escrowAsset: string,
    escrowAmount: string|number,
    beneficiaryAsset: string,
}

function docNameToDocType(doc: AtomicNetDoc) {
    return `world.atomicnet.net/ ${doc}`
}

export function formDataFromState(documentType: DocumentType, state: DemoGraphState): FormData {
    let first = documentType.search(/^[^_]+1/) !== -1; // first document looks like: "olp1_to_oms2" or "o1"
    let custodianId = first ? "custodian1" : "custodian2";
    let commonLedgerId = first ? "common_ledger1" : "common_ledger2";
    let oppositeCommonLedgerId = first ? "common_ledger2" : "common_ledger1";
    let beneficiaryId = first ? "ia1" : "ia2";
    let escrowAccountId = first ? "ia1" : "ia2";
    let giveAsset = first ? state.asset1 : state.asset2;
    let getAsset = first ? state.asset2 : state.asset1;
    const tomorrow = new Date(); tomorrow.setDate(new Date().getDate() + 1); // TODO: Base this on the submit time
    let expires = tomorrow.toUTCString();
    let giveAmountRaw = first ? state.assetAmount1 : state.assetAmount2;
    let getAmountRaw = first ? state.assetAmount2 : state.assetAmount1;
    let giveAmount = formatAssetAmount(giveAmountRaw, giveAsset) || 0;
    let getAmount = formatAssetAmount(getAmountRaw, getAsset) || 0;
    let escrowAsset = giveAsset;
    let escrowAmount = giveAmount;
    let beneficiaryAsset = getAsset;

    return {
        giveAsset: giveAsset,
        getAsset: getAsset,
        expires: expires,
        giveAmount: giveAmount,
        getAmount: getAmount,
        beneficiaryName: state.displayNames[beneficiaryId],
        beneficiaryId: state.displayNames[beneficiaryId],
        escrowAccountName: state.displayNames[escrowAccountId],
        omsId: "OMS",
        custodianName: state.displayNames[custodianId],
        custodianId: state.displayNames[custodianId],
        commonLedgerId: state.displayNames[commonLedgerId],
        oppositeCommonLedgerId: state.displayNames[oppositeCommonLedgerId],
        exchangeId: state.displayNames["exchange"],
        investmentAdvisor: state.displayNames[beneficiaryId],
        escrowAsset: escrowAsset,
        escrowAmount: escrowAmount,
        beneficiaryAsset: beneficiaryAsset,
    };
}

// Return document details used to show the details of AtomicNet documents including
// a detailed description.
export function getDocumentDetails(documentType: DocumentType, data: FormData, scenario: DemoScenario) {
    const document = getScenarioDocuments(scenario)[documentType];
    const doc = document.longDocType;
    const docType = docNameToDocType(doc);
    const desc = getDocumentDescription(document, data, scenario)

    let rows: (string | number)[][] = [];
    if (doc === "order") {
        rows = [
            ["Investment Advisor", data.investmentAdvisor],
            ["Beneficiary Name", data.beneficiaryName],
            ["Give Asset Id", data.giveAsset],
            ["Give Amount", data.giveAmount],
            ["Get Asset Id", data.getAsset],
            ["Get Amount", data.getAmount],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "custodian_beneficiary_authorization_request") {
        rows = [
            ["Beneficiary Name", data.beneficiaryName],
            ["Signer", data.omsId],
            ["Asset Id", data.beneficiaryAsset],
            ["Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "custodian_beneficiary_authorization") {
        rows = [
            ["Beneficiary Name", data.beneficiaryName],
            ["Signer", data.custodianId],
            ["Asset Id", data.beneficiaryAsset],
            ["Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ]
    } else if (doc === "custodian_escrow_confirmation_request") {
        rows = [
            ["Escrow Account Name", data.escrowAccountName],
            ["Signer", data.omsId],
            ["Asset Id", data.escrowAsset],
            ["Amount", data.escrowAmount],
            ["Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "custodian_escrow_confirmation") {
        rows = [
            ["Escrow Account Name", data.escrowAccountName],
            ["Signer", data.custodianId],
            ["Asset Id", data.escrowAsset],
            ["Amount", data.escrowAmount],
            ["Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "transfer_beneficiary_authorization_request") {
        rows = [
            ["Beneficiary Name", data.beneficiaryName],
            ["Signer", data.custodianId],
            ["Asset Id", data.beneficiaryAsset],
            ["Requested Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "transfer_beneficiary_authorization") {
        rows = [
            ["Beneficiary Name", data.beneficiaryName],
            ["Signer", data.commonLedgerId],
            ["Asset Id", data.beneficiaryAsset],
            ["Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ]
    } else if (doc === "transfer_escrow_confirmation_request") {
        rows = [
            ["Escrow Account Name", data.escrowAccountName],
            ["Signer", data.custodianId],
            ["Asset Id", data.escrowAsset],
            ["Amount", data.escrowAmount],
            ["Requested Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "transfer_escrow_confirmation") {
        rows = [
            ["Escrow Account Name", data.escrowAccountName],
            ["Signer", data.oppositeCommonLedgerId],
            ["Asset Id", data.escrowAsset],
            ["Amount", data.escrowAmount],
            ["Expires", data.expires],
            ["Requirements", "Only US Regulated Institutions"],
        ];
    } else if (doc === "quote_request") {
        rows = [
            ["Give Asset", data.giveAsset],
            ["Give Amount", data.giveAmount],
            ["Get Asset", data.getAsset],
            ["Requested Expiration", data.expires],
        ];
    } else if (doc === "order_bundle") {
        rows = [
            ["Give Asset", data.giveAsset],
            ["Give Amount", data.giveAmount],
            ["Get Asset", data.getAsset],
            ["Get Amount", data.getAmount],
        ];
    } else if (doc === "transfer_order_leg_processed") {
        rows = [
            ["Signer", data.commonLedgerId],
        ];
    } else if (doc === "order_leg_processed") {
        rows = [
            ["Signer", data.custodianId],
        ];
    } else if (doc === "order_execution_confirmation") {
        rows = [
            ["Signer", scenario === "no_exchange" ? data.omsId : data.exchangeId],
        ];
    } else if (doc === "transaction_completed") {
        rows = [
            ["Signer", data.omsId],
        ];
    }

    return {
        docType: docType,
        description: desc,
        data: rows
    };
}

function getDocumentDescription(document: AtomicNetDocument, data: FormData, scenario: DemoScenario): string {
    const fullDocName = fullDocumentName(document);
    const doc = document.longDocType;
    const matchingActor = scenario === "no_exchange" ? 'OMS' : "exchange";

    if (doc === "order") {
        return `
${data.investmentAdvisor} initiated a purchase or sale by specifying the relevant assets, custodians,
amounts, and prices to their Order Management System.        
`;
    } else if (doc === "custodian_beneficiary_authorization_request" || doc === "transfer_beneficiary_authorization_request") {
        return `
The ${fullDocName} is a request for ${data.custodianName}'s (${data.custodianId}) commitment that the
beneficiary, ${data.beneficiaryName} (${data.beneficiaryId}), is authorized to receive ${data.beneficiaryAsset} from any
corresponding party that has a valid Escrow Authorization from ${data.custodianName} until it expires, as long as all
other institutions involved in the transaction are US regulated institutions.
`;
    } else if (doc === "custodian_beneficiary_authorization" || doc === "transfer_beneficiary_authorization") {
        return `
The ${fullDocName} is ${data.custodianName}'s (${data.custodianId}) commitment that the beneficiary,
${data.beneficiaryName} (${data.beneficiaryId}), is authorized to receive ${data.beneficiaryAsset} from any corresponding party
that has a valid Escrow Authorization from ${data.custodianName} until it expires, as long as all other institutions
involved in the transaction are US regulated institutions.
`;
    } else if (doc === "custodian_escrow_confirmation_request" || doc === "transfer_escrow_confirmation_request") {
        return `
The ${fullDocName} is a request for ${data.custodianName}'s (${data.custodianId}) certification that
${data.escrowAmount} of the specified asset, ${data.escrowAsset} has been segregated, escrowed, and is clear to trade.

The asset will be authorized for transfer to any corresponding party with a Beneficiary Authorization from
${data.custodianName} until the escrow expiration time.        
`;
    } else if (doc === "custodian_escrow_confirmation" || doc === "transfer_escrow_confirmation") {
        return `
The ${fullDocName} is ${data.custodianName}'s (${data.custodianId}) certification that
${data.escrowAmount} of the specified asset, ${data.escrowAsset} has been segregated, escrowed, and is clear to trade. The
asset will be authorized for transfer to any corresponding party with a Beneficiary Authorization from
${data.custodianName} until the escrow expiration time.        
`;
    } else if (doc === "quote_request") {
        return `
A ${fullDocName} is sent to liquidity providers to receive in turn an Order Bundle from a liquidity provider.  Note that
the ${fullDocName} may be sent to multiple liquidity providers, and it is up to the OMS to select the best available
terms.

That Order Bundle can that be combined into a Order Execution Confirmation that the OMS can then send to the ABC to
trigger the settlement process.
`;
    } else if (doc === "order_bundle") {
        const actor = scenario === "no_exchange" ? "the OMS which sent the Quote Request" : "an exchange";

        return `
The Order Management System bundled both the signed Beneficiary Authorization and signed Escrow Authorization along with
order details into a signed ${fullDocName} which is sent to ${actor}. 
`;
    } else if (doc === "order_execution_confirmation") {

        return `
The ${matchingActor} matched two Order Bundle documents and then created, signed, and memorialized an
${fullDocName} on the ABC. The Order Execution Confirmation references both crossing orders, both Escrow
Authorization, and both Beneficiary Authorizations.  The Order Execution Confirmation is memorialized to the ABC so that
it is indisputably be distributed to by all involved parties. The Order Execution Confirmation is memorialized on the
ABC as a cryptographically provable way to reliably distribute the Order Execution Confirmation so that the custodians
may complete the transfer and avoid legal disputes.
`;
    } else if (doc === "order_leg_processed" || doc === "transfer_order_leg_processed") {
        return `
The ${fullDocName} is a signed certification by ${data.custodianName} (${data.custodianId}) that
it has completed its leg(s) of the order. The Order Leg Processed Confirmation is memorialized by each custodian on
the ABC. When the Order Management System detects that all legs are complete by monitoring the ABC, it
will send a Transaction Completed Confirmation to ${data.investmentAdvisor}.
`;
    } else if (doc === "transaction_completed") {
        return `
The ${fullDocName} contains all the elements necessary to prove that all parties certified
that they completed the actions required. This includes all the prior signed documents.
`;
    } else { // catch all
        return `
Document Details missing for: ${doc}
`;
    }
}
